<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>รายงานการเชื่อมต่อไวไฟ</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          @input="onDateChange"
        />
      </div>
      <!-- datepicker -->
      <!-- <b-button @click="loaddata()" variant="primary">ดึงข้อมูล</b-button> -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        ref="chart"
        :height="450"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
        v-if="Loading"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "../../charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";
// import chartjsData from "../../charts-and-maps/charts/chartjs/chartjsData";
import { $themeColors } from "@themeConfig";
import api from "@/api";
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      Loading: false,
      fromDate: "",
      toDate: "",
      dataChart: [],
      labels: [],
      rangePicker: [new Date(), new Date()],
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 1800,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    };
  },

  mounted() {
    this.fromDate = this.$moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.toDate = this.$moment().format("YYYY-MM-DD");
    this.rangePicker[0] = this.fromDate;
    this.rangePicker[1] = this.toDate;
    this.loaddata();
  },

  methods: {
    click_loaddata() {
      
      this.loaddata();
    },
    onDateChange() {
      console.log(this.rangePicker);
      if (this.rangePicker.search("to") > 0) {
        const myArr = this.rangePicker.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        console.log(" myArr[0] :>> ", myArr[0]);
        this.$nextTick(() => {
          this.loaddata();
        });
      }
    },

    loaddata() {
      this.Loading = false;
      const params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      api
        .post("/report_user_use", params)
        .then((response) => {
          console.log("report_user_use.data :>> ", response.data);
          this.dataChart = response.data.data;
          this.labels = response.data.labels;
          this.latestBarChart = {
            ...this.latestBarChart,
            ...{
              data: {
                labels: response.data.labels,
                datasets: [
                  {
                    data: response.data.data,
                    backgroundColor: chartColors.successColorShade,
                    borderColor: "transparent",
                  },
                ],
              },
            },
          };
          console.log("this.latestBarChart :>> ", this.latestBarChart);
          this.Loading = true;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
